<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex">
        <el-col>
          <el-autocomplete
            value-key="name"
            :value="CategoryName"
            :fetch-suggestions="fetchCategories"
            placeholder="Selecione uma categoria de produtos"
            @select="selectCategory"
          >
            <template #prepend>Categoria de Produto:</template>
          </el-autocomplete>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Estado do Produto:</h4>
        <el-switch
          v-model="newProduct.is_active"
          active-text="Ativo"
          inactive-text="Inativo"
        >
        </el-switch>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Identificação:</h4>
      </el-row>
      <base-input
        label="Nome:"
        hint="Nome que será exibido na hora da venda"
        v-model="newProduct.name"
      >
      </base-input>
      <base-input
        label="Un. medida:"
        hint="Kg. ou Peça"
        v-model="newProduct.un"
      >
      </base-input>
      <el-row type="flex" align="center" justify="space-between">
        <h4>Forma de Armazenamento:</h4>
      </el-row>
      <el-row
        v-for="i in FilterPropertyByType('storage')"
        :key="i"
        type="flex"
        justify="space-between"
      >
        <el-col :md="20">
          <base-input disabled v-model="i.name" label="#"> </base-input>
        </el-col>
        <el-switch
          @change="switchProductProperty(i, $event)"
          :value="hasCategory(i)"
        >
        </el-switch>
      </el-row>

      <el-row type="flex" align="center" justify="space-between">
        <h4>Forma de Corte:</h4>
      </el-row>
      <el-row
        v-for="i in FilterPropertyByType('cutting')"
        :key="i"
        type="flex"
        justify="space-between"
      >
        <el-col :md="20">
          <base-input disabled v-model="i.name" label="#"> </base-input>
        </el-col>
        <el-switch
          @change="switchProductProperty(i, $event)"
          :value="hasCategory(i)"
        >
        </el-switch>
      </el-row>

      <el-row type="flex" align="center" justify="space-between">
        <h4>Composição dos Produtos:</h4>
      </el-row>
      <el-row
        v-for="i in FilterPropertyByType('composition')"
        :key="i"
        type="flex"
        justify="space-between"
      >
        <el-col :md="20">
          <base-input disabled v-model="i.name" label="#"> </base-input>
        </el-col>
        <el-switch
          @change="switchProductProperty(i, $event)"
          :value="hasCategory(i)"
        >
        </el-switch>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["product", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      newProduct: this.product || { name: "Novo produto", is_active: true },
      isLoadingSave: false,
      systemActions: [],
    };
  },
  watch: {
    product(v) {
      this.newProduct = v || {};

      this.fetchProducts();
    },
  },
  computed: {
    CategoryName() {
      return this?.newProduct?.category?.name || "";
    },
    ModalTitle() {
      return "uid" in (this.newProduct || {})
        ? "Editar Produto"
        : "Cadastrar Produto";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    switchProductProperty(v, event) {
      if (!Array.isArray(this.newProduct.properties))
        this.newProduct.properties = [];

      if (event) this.newProduct.properties.push(v);
      else
        this.newProduct.properties = this.newProduct.properties.filter(
          (s) => s.name != v.name
        );
    },
    hasCategory(c) {
      return (
        Array.isArray(this.newProduct?.properties) &&
        this.newProduct.properties.some((e) => e.name === c.name)
      );
    },
    selectCategory(v) {
      this.newProduct.category = v;
    },
    fetchCategories(query, callback) {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then(
          (json) => callback(json),
          () => callback([])
        );
    },
    FilterPropertyByType(type) {
      return Array.isArray(this.newProduct?.category?.properties)
        ? this.newProduct.category.properties
            //.map((e, i) => ({ id: i, ...e }))
            .filter((e) => e.type === `${type}`)
        : [];
    },
    save() {
      this.isLoadingSave = true;
      return this.product ? this.updateProduct() : this.createProduct();
    },
    createProduct() {
      fetch(`${this.$store.state.apiUrl}products`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.newProduct),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.newProduct = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o produto",
              message: e[0].message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateProduct() {
      fetch(`${this.$store.state.apiUrl}products/${this.newProduct.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.newProduct, price: null }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o produto",
              message: e[0].message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "ProductCategoryModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-switch {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
